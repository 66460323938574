import React, { useCallback, useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Box, SimpleGrid, VStack } from '@chakra-ui/react';
import Link from '@components/atoms/Link';
import dataLayerPush from '@helpers/dataLayerPush';
import { locale } from '@helpers/environment';
import { extractMenuBySlug } from '@helpers/menuData';
import { nullSwitch } from '@helpers/dataHelpers';

const linkStyles = {
  fontFamily: `heading`,
  fontWeight: `bold`,
  textDecoration: `underline`,
  _hover: {
    color: `secondary.pink`
  }
};

const FooterNav = () => {
  const { allStrapiMenus } = useStaticQuery(graphql`
    {
      allStrapiMenus {
        nodes {
          attributes {
            slug
            items {
              data {
                attributes {
                  title
                  url
                  target
                  children {
                    data {
                      attributes {
                        title
                        url
                        target
                        children {
                          data {
                            attributes {
                              title
                              url
                              target
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const menu = useMemo(() => {
    return extractMenuBySlug({
      query: nullSwitch(locale, `footer-menu-${locale}`, `footer-menu`),
      menuData: allStrapiMenus.nodes
    });
  }, [allStrapiMenus]);

  const secondaryMenu = useMemo(() => {
    return extractMenuBySlug({
      query: nullSwitch(
        locale,
        `footer-secondary-menu-${locale}`,
        `footer-secondary-menu`
      ),
      menuData: allStrapiMenus.nodes
    });
  }, [allStrapiMenus]);

  const handleNavigate = useCallback((url, title) => {
    dataLayerPush({
      event: `footer_link_click`,
      interaction: {
        click_text: title,
        link_url: url
      }
    });
  }, []);

  return (
    <SimpleGrid
      columns={{ base: 2, lg: 3, xl: 5 }}
      spacing={{ base: 6, md: 8 }}
      flex="1"
      maxWidth={{ base: `24rem`, md: `none` }}>
      {menu?.attributes?.items?.data?.map(({ attributes: column }) => (
        <VStack
          key={column.title}
          as="ul"
          listStyleType="none"
          align="start"
          spacing={{ base: 6, md: 8 }}>
          {column?.children?.data?.map(({ attributes: primaryItem }) => (
            <VStack key={primaryItem.title} as="li" align="start" spacing="3">
              <Link
                href={primaryItem.url}
                onClick={
                  () => handleNavigate(primaryItem.url, primaryItem.title)
                  // eslint-disable-next-line react/jsx-curly-newline
                }
                {...linkStyles}>
                {primaryItem.title}
              </Link>
              {primaryItem?.children?.data?.length > 0 && (
                <VStack as="ul" listStyleType="none" align="start" spacing="3">
                  {primaryItem.children.data.map(
                    ({ attributes: secondaryItem }) => (
                      <Box key={secondaryItem.title} as="li">
                        <Link
                          href={secondaryItem.url}
                          fontSize="xs"
                          onClick={
                            () =>
                              handleNavigate(
                                secondaryItem.url,
                                secondaryItem.title
                              )
                            // eslint-disable-next-line react/jsx-curly-newline
                          }
                          {...linkStyles}>
                          {secondaryItem.title}
                        </Link>
                      </Box>
                    )
                  )}
                </VStack>
              )}
            </VStack>
          ))}
        </VStack>
      ))}
      {secondaryMenu?.attributes?.items?.data?.length > 0 && (
        <Box
          gridColumn={{ base: `span 2`, md: `span 1` }}
          mt={{ base: 3, md: 0 }}>
          <SimpleGrid
            as="ul"
            listStyleType="none"
            spacingX={{ base: 6, md: 3 }}
            spacingY="3"
            columns={{ base: 2, md: 1 }}>
            {secondaryMenu.attributes.items.data.map(
              ({ attributes: primaryItem }) => (
                <Box key={primaryItem.title} as="li">
                  <Link href={primaryItem.url} fontSize="xs" {...linkStyles}>
                    {primaryItem.title}
                  </Link>
                </Box>
              )
            )}
          </SimpleGrid>
        </Box>
      )}
    </SimpleGrid>
  );
};

export default FooterNav;
