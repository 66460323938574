/*
 * - Assumes the following rules are followed with menu slugs:
 * 'menu-name' + 'locale' (locale is optional)
 * - Direct match is more strict than 'includes', this avoids incorrect
 * locales from being used for different menus.
 * - Always make sure that the menu slug matches that of the strapi
 * locale exactly, as any variance will quite possibly break the match.
 * Example: 'de' in strapi, so it should be 'menu-name-de' as the menu slug.
 */
export const extractMenuBySlug = ({ query, menuData }) => {
  const normalizedQuery = query.toLowerCase();

  const menuResult = menuData.find((node) => {
    const normalizedNodeSlug = node.attributes.slug.toLowerCase();
    const queryMatchIndex = normalizedNodeSlug.indexOf(normalizedQuery);

    if (queryMatchIndex > -1) {
      // Anything after the query match index should directly match the query given
      const subMatch = normalizedNodeSlug.substring(queryMatchIndex);
      return subMatch === normalizedQuery;
    }

    return false;
  });

  return typeof menuResult !== `undefined` && menuResult !== null
    ? menuResult
    : {
        attributes: {
          items: {
            data: []
          }
        }
      };
};
