import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo
} from 'react';
import { graphql, useStaticQuery, Link as GatsbyLink } from 'gatsby';
import dataLayerPush from '@helpers/dataLayerPush';

import {
  Box,
  Flex,
  VStack,
  Show,
  Hide,
  Accordion,
  Link,
  useDisclosure
} from '@chakra-ui/react';
import LocaleOptions from '@components/molecules/LocaleOptions';
import NavDrop from '@components/molecules/NavDrop';
import NavDropMobile from '@components/molecules/NavDropMobile';
import Burger from '@components/atoms/Burger';
import Search from '@components/atoms/Search';
import SiteLogo from '@components/atoms/SiteLogo';
import Image from '@components/atoms/Image';
import { locale } from '@helpers/environment';
import { extractMenuBySlug } from '@helpers/menuData';
import { nullSwitch } from '@helpers/dataHelpers';

export const height = { base: `94px`, md: `108px` };

const Header = () => {
  const {
    allStrapiMenus,
    site,
    allLocale,
    siteSettings: { siteLogo: customSiteLogo = null, additionalLogo = null }
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
      allStrapiMenus {
        nodes {
          attributes {
            title
            slug
            items {
              data {
                attributes {
                  title
                  url
                  target
                  children {
                    data {
                      attributes {
                        title
                        url
                        target
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      allLocale {
        nodes {
          code
          name
        }
      }
      siteSettings: strapiSiteSetting {
        siteLogo {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
            url
          }
          alternativeText
        }
        additionalLogo {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
            url
          }
          alternativeText
        }
      }
    }
  `);

  // Checks if environment has disabled the selector, or, more than one locale exists.
  const showLocaleSwitcher =
    process.env.BUILD_WITH_LANGUAGE_SELECTOR !== false &&
    process.env.BUILD_WITH_LANGUAGE_SELECTOR !== `false` &&
    process.env.GATSBY_BUILD_WITH_LANGUAGE_SELECTOR !== false &&
    process.env.GATSBY_BUILD_WITH_LANGUAGE_SELECTOR !== `false` &&
    typeof locale !== `undefined` &&
    locale !== null &&
    allLocale.nodes.length > 1;

  const strapiMenus = useMemo(() => {
    return extractMenuBySlug({
      query: nullSwitch(locale, `header-${locale}`, `header`),
      menuData: allStrapiMenus.nodes
    });
  }, [allStrapiMenus]);

  const { isOpen, onToggle } = useDisclosure();
  const [showDesktopNav, setShowDesktopNav] = useState(true);
  const [showMiddle, setShowMiddle] = useState(true);
  const [currentNavItemIndex, setCurrentNavItemIndex] = useState(-1);

  const hasCustomSiteLogo =
    typeof customSiteLogo !== `undefined` && customSiteLogo !== null;

  const hasAdditionalLogo =
    typeof additionalLogo !== `undefined` && additionalLogo !== null;

  const desktopLogoSize = `11.25rem`;

  const mobileLogoContainerSize = `12.75rem`;
  const mobileLogoSize = `100%`;

  const navItemsRef = useRef([]);

  const handleClick = useCallback((eventName, url, title, navLevel) => {
    dataLayerPush({
      event: eventName,
      interaction: {
        click_text: title,
        link_url: url,
        nav_level: navLevel || 1
      }
    });
  }, []);

  const onRef = (element, elementIndex) => {
    navItemsRef.current[elementIndex] = element;
  };

  useEffect(() => {
    /**
     * onScroll method for the header
     * @param {WheelEvent} event onScroll event
     */
    const onScroll = (event) => {
      if (event.deltaY > 0) setShowDesktopNav(false);
      else if (event.deltaY < 0) setShowDesktopNav(true);
    };

    window.addEventListener(`wheel`, onScroll);

    return () => window.removeEventListener(`wheel`, onScroll);
  }, []);

  useEffect(() => {
    /**
     * onKeyDown method for the header
     * @param {KeyboardEvent} event onKeyDown event
     */
    const onKeyDown = ({ key }) => {
      if (typeof strapiMenus.attributes.items.data === `undefined`) {
        return;
      }

      const menuItems = strapiMenus.attributes.items.data.map(
        (item, index) => index
      );

      if (typeof key === `undefined`) {
        return;
      }

      if (key.toLowerCase() === `arrowleft`) {
        const previousIndex = currentNavItemIndex - 1;
        const previousItem =
          previousIndex < 0 ? menuItems.length - 1 : previousIndex;

        if (navItemsRef.current && navItemsRef.current.length > 0) {
          const currentElement = navItemsRef.current[previousItem];
          currentElement.focus();
        }

        setCurrentNavItemIndex(previousItem);
      }

      if (key.toLowerCase() === `arrowright`) {
        const nextIndex = currentNavItemIndex + 1;
        const nextItem = nextIndex > menuItems.length - 1 ? 0 : nextIndex;

        if (navItemsRef.current && navItemsRef.current.length > 0) {
          const currentElement = navItemsRef.current[nextItem];
          currentElement.focus();
        }

        setCurrentNavItemIndex(nextItem);
      }
    };

    window.addEventListener(`keydown`, onKeyDown);

    return () => window.removeEventListener(`keydown`, onKeyDown);
  }, [currentNavItemIndex, strapiMenus.attributes.items.data]);

  return typeof strapiMenus?.attributes === `undefined` ||
    strapiMenus.attributes?.items?.data?.length === 0 ? (
    // eslint-disable-next-line react/jsx-indent
    <Box color="red">Menu render error</Box>
  ) : (
    <Box position="relative">
      <Hide above="lg">
        <Flex
          w="100vw"
          h={height.base}
          bg={hasAdditionalLogo === true ? `white` : `transparent`}
          alignItems="flex-start"
          justifyContent="space-between"
          position="fixed"
          top="0"
          zIndex="3">
          <Link
            to="/"
            as={GatsbyLink}
            onClick={
              () => handleClick(`nav_logo_click`, `/`, `logo`, `logo`)
              // eslint-disable-next-line react/jsx-curly-newline
            }>
            <Box
              px="5"
              h={height.base}
              minW={mobileLogoContainerSize}
              pt={hasCustomSiteLogo === true ? `0.188rem` : `1.875rem`}
              backgroundColor="#fff"
              borderBottomRightRadius="xs"
              borderBottomLeftRadius="xs"
              boxShadow="lgV">
              <Box w="100%" h="100%" maxH="5.125rem">
                <Flex gap={4} h="100%" alignItems="center">
                  <Flex alignSelf="center" w="100%" alignItems="center">
                    {hasCustomSiteLogo === true ? (
                      <Box w={mobileLogoSize}>
                        <Image image={customSiteLogo} />
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          svg: {
                            width: hasAdditionalLogo === true ? `64px` : `auto`,
                            height: `auto`
                          }
                        }}>
                        <SiteLogo title={site.siteMetadata.title} />
                      </Box>
                    )}
                  </Flex>
                  {hasAdditionalLogo === true && (
                    <Box
                      w={mobileLogoSize}
                      sx={{
                        '.gatsby-image-wrapper': {
                          maxH: `3.5rem`
                        }
                      }}>
                      <Image image={additionalLogo} />
                    </Box>
                  )}
                </Flex>
              </Box>
            </Box>
          </Link>
          <Box w="100%">
            <Box
              h="4.625rem"
              w="100%"
              backgroundColor="#fff"
              boxShadow={hasAdditionalLogo === true ? `none` : `lgV`}
            />
            <Box
              h="10px"
              w="calc(100% + 10px)"
              ml="-5px"
              mt="-5px"
              border="5px solid #fff"
              borderBottom="none"
              backgroundColor="transparent"
              borderTopLeftRadius="xs"
              borderTopRightRadius="xs"
            />
          </Box>
          <Box
            px="5"
            h={height.base}
            pt="37px"
            backgroundColor="#fff"
            borderBottomRightRadius="xs"
            borderBottomLeftRadius="xs"
            boxShadow="lgV">
            <Burger isOpen={isOpen} onClick={onToggle} color="#fff" />
          </Box>
        </Flex>
        <Box
          zIndex="2"
          position="fixed"
          top="0"
          right="0"
          left="0"
          bottom="0"
          display={isOpen ? `block` : `none`}
          backgroundColor="primary.dark-blue"
          mt="74px">
          <Box mx="5" mt="12">
            {showLocaleSwitcher && <LocaleOptions />}
          </Box>
          <Search
            w="calc(100% - 2rem)"
            mx="4"
            mt={showLocaleSwitcher ? `3` : `12`}
          />
          <Flex flexDir="column" mt="1rem">
            <Accordion allowToggle>
              {strapiMenus.attributes.items.data.length > 0 &&
                strapiMenus.attributes.items.data.map((category) => (
                  <NavDropMobile
                    key={category.attributes.title}
                    menuItem={category}
                  />
                ))}
            </Accordion>
          </Flex>
        </Box>
      </Hide>
      <Show above="lg">
        <Flex w="100%" justifyContent="center">
          <Flex
            w="100vw"
            maxW="1320px"
            h={height.md}
            alignItems="center"
            justifyContent="space-between"
            px="44px"
            pr={{ base: null, xl: `4` }}
            position="fixed"
            top={showDesktopNav ? `0px` : `-${height.md}`}
            zIndex="2"
            background="#fff"
            borderBottomLeftRadius="md"
            borderBottomRightRadius="xs"
            boxShadow="lg"
            transition="top 0.5s ease">
            <Link
              to="/"
              as={GatsbyLink}
              onClick={
                () => handleClick(`nav_logo_click`, `/`, `logo`, `logo`)
                // eslint-disable-next-line react/jsx-curly-newline
              }>
              <Box maxH={height.md} overflow="hidden">
                <Flex gap={4}>
                  <Flex alignSelf="center" w={desktopLogoSize}>
                    {hasCustomSiteLogo === true ? (
                      <Box w="100%">
                        <Image image={customSiteLogo} />
                      </Box>
                    ) : (
                      <SiteLogo title={site.siteMetadata.title} />
                    )}
                  </Flex>
                  {hasAdditionalLogo === true && (
                    <Box
                      w={desktopLogoSize}
                      sx={{
                        '.gatsby-image-wrapper': {
                          maxH: height.md
                        },
                        img: {
                          maxH: height.md
                        }
                      }}>
                      <Image image={additionalLogo} />
                    </Box>
                  )}
                </Flex>
              </Box>
            </Link>
            <Flex mt="0.4rem" ml="auto" justifyContent="end" role="navigation">
              {showMiddle &&
                strapiMenus.attributes.items.data.length > 0 &&
                strapiMenus.attributes.items.data.map(
                  (category, elementIndex) => (
                    <Box role="presentation" key={category.attributes.title}>
                      <NavDrop
                        ref={(element) => onRef(element, elementIndex)}
                        menuItem={category}
                        show={!showDesktopNav}
                      />
                    </Box>
                  )
                )}
              <Hide above="xl">
                <Search
                  compact
                  onToggle={setShowMiddle}
                  position={showMiddle ? `relative` : `absolute`}
                  right={showMiddle ? `0` : `2.75rem`}
                  top={showMiddle ? `0` : `2.7rem`}
                />
              </Hide>
            </Flex>
            <Show above="xl">
              <VStack mt="0.1rem" mb="0" justifyContent="end">
                {showLocaleSwitcher && <LocaleOptions />}
                <Search />
              </VStack>
            </Show>
            <Hide above="xl">
              <Box position="absolute" top="4" right="12" w="60">
                {showLocaleSwitcher && <LocaleOptions />}
              </Box>
            </Hide>
          </Flex>
        </Flex>
      </Show>
    </Box>
  );
};

export default Header;
