/**
 * Checks if value is null or undefined
 * @param {*} value Accepts any value type
 * @returns {Boolean} Returns true if value is nullish, else false
 */
export const isNil = (value) => value == null;

/**
 * Checks if and values are null of undefined
 * @param {Array} values Accepts an array of any value types
 * @returns {Boolean} Returns true if any value is nullish, else false
 */
export const someAreNil = (values) => values.some((entry) => isNil(entry));

/**
 * Checks if ceiled number is odd
 * @param {Number} number Accepts a number
 * @returns {Boolean} Returns true if number is odd
 */
export const isOdd = (number) => number % 2;

/**
 * Clamps value between range
 * @param {Number} value Current value number
 * @param {Number} min Min clamp value
 * @param {Number} max Max clamp value
 * @returns {Number} Clamped value
 */
export const clamp = (value, min, max) => Math.min(Math.max(value, min), max);

/**
 * Switches depending if data is null & undefined or not
 * @param {Data} data Data to check
 * @param {Data} positive Return this if not null
 * @param {Data} negative Return this if null
 * @returns {Data} Result of query
 */
export const nullSwitch = (data, positive, negative) => {
  return typeof data !== `undefined` && data !== null ? positive : negative;
};
