exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dev-components-js": () => import("./../../../src/pages/_dev/components.js" /* webpackChunkName: "component---src-pages-dev-components-js" */),
  "component---src-pages-dev-foundations-js": () => import("./../../../src/pages/_dev/foundations.js" /* webpackChunkName: "component---src-pages-dev-foundations-js" */),
  "component---src-pages-jobs-index-js": () => import("./../../../src/pages/jobs/index.js" /* webpackChunkName: "component---src-pages-jobs-index-js" */),
  "component---src-pages-mondelezicecream-index-js": () => import("./../../../src/pages/mondelezicecream/index.js" /* webpackChunkName: "component---src-pages-mondelezicecream-index-js" */),
  "component---src-pages-mondelezicecream-strapi-mondelez-product-slug-js": () => import("./../../../src/pages/mondelezicecream/{StrapiMondelezProduct.slug}.js" /* webpackChunkName: "component---src-pages-mondelezicecream-strapi-mondelez-product-slug-js" */),
  "component---src-pages-products-index-js": () => import("./../../../src/pages/products/index.js" /* webpackChunkName: "component---src-pages-products-index-js" */),
  "component---src-pages-products-strapi-product-slug-js": () => import("./../../../src/pages/products/{StrapiProduct.slug}.js" /* webpackChunkName: "component---src-pages-products-strapi-product-slug-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-strapi-contact-form-slug-js": () => import("./../../../src/pages/{StrapiContactForm.slug}.js" /* webpackChunkName: "component---src-pages-strapi-contact-form-slug-js" */),
  "component---src-pages-strapi-search-listing-slug-js": () => import("./../../../src/pages/{StrapiSearchListing.slug}.js" /* webpackChunkName: "component---src-pages-strapi-search-listing-slug-js" */),
  "component---src-templates-articles-listing-js": () => import("./../../../src/templates/articles/listing.js" /* webpackChunkName: "component---src-templates-articles-listing-js" */),
  "component---src-templates-articles-page-js": () => import("./../../../src/templates/articles/page.js" /* webpackChunkName: "component---src-templates-articles-page-js" */),
  "component---src-templates-brands-listing-js": () => import("./../../../src/templates/brands/listing.js" /* webpackChunkName: "component---src-templates-brands-listing-js" */),
  "component---src-templates-brands-page-js": () => import("./../../../src/templates/brands/page.js" /* webpackChunkName: "component---src-templates-brands-page-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

